.Area {
  display: flex;
  flex-direction: column;
  color: white;
  height: auto;
}

.Header {
  background: #11111a;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 38px;
  height: 10vh;
  background: #11111a;
  border-bottom: 1px solid white;

  a {
    margin-left: 40px;
    z-index: 9999;
    cursor: pointer;
  }

  span {
    padding: 0px;
    margin: -50px;
    padding-left: 100px;
  }

  svg {
    position: relative;
    top: 5px;
    cursor: pointer;
    color: white;
  }
}

.Content {
  height: auto;
  font-size: 24px;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 140px;

  h3 {
    font-size: 28px;
  }
}

.Content form {
  display: flex;
  flex-direction: column;
}

.Content form label {
  display: flex;
  flex-direction: column;
  font-size: 24px;
}

.Content form input {
  margin: 20px 0px 20px 0px;
  font-size: 24px;
  height: 40px;
  width: 50%;
}

.Content form textarea {
  margin: 20px 0px 20px 0px;
  font-size: 24px;
  height: 200px;
  width: 50%;
  resize: none;
}

.Content form button {
  color: black;
  background: white;
  border: none;
  margin: 40px 0px 20px 0px;
  font-size: 28px;
  height: 40px;
  width: 25%;
}

.Content form select {
  margin: 20px 0px 20px 0px;
  width: 50%;
  height: 40px;
  font-size: 26px;
  border: 0;
  outline: 1px inset white;
  outline-offset: -1px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Content {
    height: auto;
    font-size: 24px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  
    h3 {
      font-size: 28px;
    }
  }

  .Header {
    font-size: 28px;
  }

  .Content form input {
    width: 90%;
  }

  .Content form textarea {
    width: 90%;
  }
  
  .Content form button {
    width: 90%;
  }
  
  .Content form select {
    width: 90%;
  }
}

