.Home {
  display: flex;
  color: white;
  flex-direction: column;
  font-family: "Muli", sans-serif;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.Title {
  display: flex;
  flex-direction: row;
  font-size: 38px;
  font-family: "Kaushan Script", cursive;
  font-weight: bold;
  width: 100%;
  height: 10vh;
  padding-top: 20px;
  background: #11111a;
  border-bottom: 1px solid white;

  svg {
    position: relative;
    top: -10px;
    height: 40px;
    height: auto;
    margin-left: 100px;
    width: 40px;
  }

  span {
    width: 20%;
    padding-left: 30px;
    height: auto;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    width: 60%;
    margin: 0;
    padding-right: 100px;

    li {
      cursor: pointer;
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
      padding-right: 20px;
      list-style: none;
    }
  }
}

.Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 85vh;
  width: 100%;
  overflow: hidden;
}

.Introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 40px;
  margin-left: 100px;
  padding: 50px;
  width: 40%;
  background: #17172928;

  span {
    margin-bottom: 20px;
  }
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 40px;
  padding-right: 100px;
  width: 50%;
}

.Links li {
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Links a {
  color: white;
  text-decoration: underline;
  text-decoration-color: rgb(194, 194, 194);
  text-align: right;
  cursor: pointer;
}

.Footer {
  height: 5vh;
  width: 100%;
  font-size: 12px;
  color: grey;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #11111a;

  span {
    padding-left: 100px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 28px;
  
    svg {
      margin-left: 50px;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Introduction {
    font-size: 24px;
    width: 60%;
    padding: 20px;
    margin-left: 50px;

    span {
      padding: 0px;
    }
  }

  .Links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 21px;
  }

  .Footer {
    color: white;
  
    span {
      padding-left: 50px;
    }
  }

}
