body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #000428; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Home_Home__3rkb8 {
  display: -webkit-flex;
  display: flex;
  color: white;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Muli", sans-serif;
  height: 100vh;
  width: 100%;
  overflow: hidden; }

.Home_Title__3Mw0g {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 38px;
  font-family: "Kaushan Script", cursive;
  font-weight: bold;
  width: 100%;
  height: 10vh;
  padding-top: 20px;
  background: #11111a;
  border-bottom: 1px solid white; }
  .Home_Title__3Mw0g svg {
    position: relative;
    top: -10px;
    height: 40px;
    height: auto;
    margin-left: 100px;
    width: 40px; }
  .Home_Title__3Mw0g span {
    width: 20%;
    padding-left: 30px;
    height: auto; }
  .Home_Title__3Mw0g ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    height: auto;
    width: 60%;
    margin: 0;
    padding-right: 100px; }
    .Home_Title__3Mw0g ul li {
      cursor: pointer;
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
      padding-right: 20px;
      list-style: none; }

.Home_Content__2dzcd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 85vh;
  width: 100%;
  overflow: hidden; }

.Home_Introduction__2wat4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-size: 40px;
  margin-left: 100px;
  padding: 50px;
  width: 40%;
  background: #17172928; }
  .Home_Introduction__2wat4 span {
    margin-bottom: 20px; }

.Home_Links__1SOhp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-size: 40px;
  padding-right: 100px;
  width: 50%; }

.Home_Links__1SOhp li {
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px; }

.Home_Links__1SOhp a {
  color: white;
  text-decoration: underline;
  -webkit-text-decoration-color: #c2c2c2;
          text-decoration-color: #c2c2c2;
  text-align: right;
  cursor: pointer; }

.Home_Footer__2DMG_ {
  height: 5vh;
  width: 100%;
  font-size: 12px;
  color: grey;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  background: #11111a; }
  .Home_Footer__2DMG_ span {
    padding-left: 100px; }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Home_Title__3Mw0g {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    font-size: 28px; }
    .Home_Title__3Mw0g svg {
      margin-left: 50px; }
  .Home_Content__2dzcd {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
  .Home_Introduction__2wat4 {
    font-size: 24px;
    width: 60%;
    padding: 20px;
    margin-left: 50px; }
    .Home_Introduction__2wat4 span {
      padding: 0px; }
  .Home_Links__1SOhp {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    font-size: 21px; }
  .Home_Footer__2DMG_ {
    color: white; }
    .Home_Footer__2DMG_ span {
      padding-left: 50px; } }

.Common_Area__3SgTR {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: white;
  height: auto; }

.Common_Header__1cMQN {
  background: #11111a;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 38px;
  height: 10vh;
  background: #11111a;
  border-bottom: 1px solid white; }
  .Common_Header__1cMQN a {
    margin-left: 40px;
    z-index: 9999;
    cursor: pointer; }
  .Common_Header__1cMQN span {
    padding: 0px;
    margin: -50px;
    padding-left: 100px; }
  .Common_Header__1cMQN svg {
    position: relative;
    top: 5px;
    cursor: pointer;
    color: white; }

.Common_Content__Xo2nD {
  height: auto;
  font-size: 24px;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 140px; }
  .Common_Content__Xo2nD h3 {
    font-size: 28px; }

.Common_Content__Xo2nD form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Common_Content__Xo2nD form label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 24px; }

.Common_Content__Xo2nD form input {
  margin: 20px 0px 20px 0px;
  font-size: 24px;
  height: 40px;
  width: 50%; }

.Common_Content__Xo2nD form textarea {
  margin: 20px 0px 20px 0px;
  font-size: 24px;
  height: 200px;
  width: 50%;
  resize: none; }

.Common_Content__Xo2nD form button {
  color: black;
  background: white;
  border: none;
  margin: 40px 0px 20px 0px;
  font-size: 28px;
  height: 40px;
  width: 25%; }

.Common_Content__Xo2nD form select {
  margin: 20px 0px 20px 0px;
  width: 50%;
  height: 40px;
  font-size: 26px;
  border: 0;
  outline: 1px inset white;
  outline-offset: -1px; }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Common_Content__Xo2nD {
    height: auto;
    font-size: 24px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px; }
    .Common_Content__Xo2nD h3 {
      font-size: 28px; }
  .Common_Header__1cMQN {
    font-size: 28px; }
  .Common_Content__Xo2nD form input {
    width: 90%; }
  .Common_Content__Xo2nD form textarea {
    width: 90%; }
  .Common_Content__Xo2nD form button {
    width: 90%; }
  .Common_Content__Xo2nD form select {
    width: 90%; } }


